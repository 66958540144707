<template>
  <div class="lesson-create flex-column">
    <div class="lesson-create-wraaper manage-wrapper">
      <div class="tabs__header create-container">
        <el-tabs v-model="activeName">
          <el-tab-pane label="创建课节" name="create">
            <router-view></router-view>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
  export default {
    data() {
      return {
        activeName: "create"
      };
    },
    computed: {},
    components: {},
    methods: {},
    mounted() {},
    created() {}
  };
</script>

<style lang="less" scoped>
  @import "../less/lesson_commen.less";
</style>